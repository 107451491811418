import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/database";
// Initialize Firebase
const config = {
  apiKey: "AIzaSyDe3eyMnJjg4Im6xYmgn_Qsg4i70gvstzg",
  authDomain: "baskyt-customer-app.firebaseapp.com",
  databaseURL: "https://baskyt-customer-app.firebaseio.com",
  projectId: "baskyt-customer-app",
  storageBucket: "baskyt-customer-app.appspot.com",
  messagingSenderId: "186665767098",
  appId: "1:186665767098:web:af287df3efbae555daf283",
  measurementId: "G-NHLG864D9T",
};
const firebaseApp = firebase.initializeApp(config);
firebaseApp.firestore().settings({ timestampsInSnapshots: true });
export default firebaseApp;
