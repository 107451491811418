import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import orders from "./orders.module";
import riders from "./riders.module";
import stores from "./stores.module";
import products from "./products.module";
import categories from "./categories.module";
import businessLines from "./businessLines.module";
import levels from "./levels.module";
import bonuses from "./bonuses.module";
import notifications from "./notifications.module";
import customers from "./customers.module";
import api from "./api.module";
import payments from "./payments.module";
import deals from "./deals.module";
import promoCodes from "./promoCodes.module";
import region from "./region.module";
import settingsUs from "./settings-us.module";
import settingsPk from "./settings.module";
import activeOrders from "./activeOrders.module";
import orderHistory from "./orderHistory.module";
import ordersLog from "./ordersLog.module";
import admins from "./admins.module";

// Load VueX
Vue.use(Vuex);

const modules = {
  auth,
  htmlClass,
  config,
  breadcrumbs,
  orders,
  riders,
  stores,
  products,
  categories,
  businessLines,
  levels,
  bonuses,
  notifications,
  customers,
  api,
  payments,
  deals,
  promoCodes,
  region,
  settingsPk,
  activeOrders,
  settingsUs,
  orderHistory,
  ordersLog,
  admins,
};

// Create Store
export default new Vuex.Store({
  modules,
});
