import ApiService from "@/core/services/api.service";

const prefix = "categories_module";
// Action Types

export const GET_ALL_CATEGORIES = `${prefix}categories_action_1`;
export const GET_ARCHIVED_CATEGORIES = `${prefix}categories_action_2`;

export const GET_SUB_CATEGORIES = `${prefix}sub_categories_action_1`;
export const GET_ARCHIVED_SUB_CATEGORIES = `${prefix}sub_categories_action_2`;

export const GET_SUB_PRODUCTS = `${prefix}sub_products_action_1`;

// Mutation types
export const SET_ALL_CATEGORIES = `${prefix}categories_mutation_1`;
export const SET_ALL_CATEGORIES_COUNT = `${prefix}categories_mutation_2`;
export const CATEGORIES_FETCHING = `${prefix}categories_mutation_3`;
export const CATEGORIES_FETCHED = `${prefix}categories_mutation_4`;
export const SET_ARCHIVED_CATEGORIES = `${prefix}categories_mutation_5`;
export const SET_ARCHIVED_CATEGORIES_COUNT = `${prefix}categories_mutation_7`;
export const ARCHIVED_CATEGORIES_FETCHING = `${prefix}categories_mutation_8`;
export const ARCHIVED_CATEGORIES_FETCHED = `${prefix}categories_mutation_9`;
export const ACTIVE_CATEGORIES_ERROR = `${prefix}categories_mutation_10`;
export const ARCHIVED_CATEGORIES_ERROR = `${prefix}categories_mutation_11`;

export const SET_SUB_CATEGORIES = `${prefix}sub_categories_mutation_1`;
export const SET_SUB_CATEGORIES_CATEGORY = `${prefix}sub_categories_mutation_2`;
export const RESET_SUB_CATEGORY = `${prefix}sub_categories_mutation_3`;
export const SET_SUB_CATEGORIES_COUNT = `${prefix}sub_categories_mutation_4`;
export const SUB_CATEGORIES_FETCHING = `${prefix}sub_categories_mutation_5`;
export const SUB_CATEGORIES_FETCHED = `${prefix}sub_categories_mutation_6`;

export const SET_SUB_PRODUCTS = `${prefix}sub_products_mutation_1`;
export const SET_SUB_PRODUCTS_COUNT = `${prefix}sub_products_mutation_2`;
export const SUB_PRODUCTS_FETCHING = `${prefix}sub_products_mutation_3`;
export const SUB_PRODUCTS_FETCHED = `${prefix}sub_products_mutation_4`;
// State
const state = {
  allCategories: [],
  allCategoriesCount: 0,
  archivedCategories: [],
  archivedCategoriesCount: 0,
  activeCategoriesError: null,
  archivedCategoriesError: null,

  categoriesFetching: true,
  archviedCategoriesFetching: true,
  subCategoriesCount: 0,
  subCategoriesFetching: false,
  subCategories: [],
  categorySubId: null,
  subProducts: [],
  subProductsCount: 0,
  subProductsFetching: false,
};
// Getters
const getters = {
  getAllCategories(state) {
    return state.allCategories;
  },
  getAllCategoriesCount(state) {
    return state.allCategoriesCount;
  },
  getCategoriesFetching(state) {
    return state.categoriesFetching;
  },
  getSubCategoryCategory(state) {
    return state.categorySubId;
  },
  getSubCategoriesCount(state) {
    return state.subCategoriesCount;
  },
  getSubCategoriesFetching(state) {
    return state.subProductsFetching;
  },
  getAllSubCats(state) {
    return state.subCategories;
  },
  getSubProducts(state) {
    return state.subProducts;
  },
  getSubProductsCount(state) {
    return state.subProductsCount;
  },
  getSubProductsFetching(state) {
    return state.subProductsFetching;
  },
};
// Actions
const actions = {
  [GET_ALL_CATEGORIES](context, options = {}) {
    context.commit(CATEGORIES_FETCHING);
    context.commit(ACTIVE_CATEGORIES_ERROR, null);
    context.commit(SET_ALL_CATEGORIES_COUNT, 0);

    let limit = [0, 10];
    if ("limit" in options) {
      limit = options.limit;
    }

    if ("businessLineId" in options) {
      return new Promise((resolve) => {
        ApiService.post("/businessLine/getBusinessLinesCategories", options)
          .then(({ data }) => {
            context.commit(SET_ALL_CATEGORIES, data.data);
            context.commit(SET_ALL_CATEGORIES_COUNT, data.data.length);
            context.commit(CATEGORIES_FETCHED);
            resolve(data);
          })
          .catch(({ data }) => {
            context.commit(
              ACTIVE_CATEGORIES_ERROR,
              `${data.message} on ${data.url}`
            );
            context.commit(CATEGORIES_FETCHED);
            resolve(data);
          });
      });
    }
    options.archived = 0;
    return new Promise((resolve) => {
      ApiService.post(
        `/categories/getAllCategories/${limit[0]}/${limit[1]}`,
        options
      )
        .then(({ data }) => {
          context.commit(SET_ALL_CATEGORIES, data.data.categories);
          context.commit(SET_ALL_CATEGORIES_COUNT, data.count);
          context.commit(CATEGORIES_FETCHED);
          resolve(data);
        })
        .catch(({ data }) => {
          context.commit(
            ACTIVE_CATEGORIES_ERROR,
            `${data.message} on ${data.url}`
          );
          context.commit(CATEGORIES_FETCHED);
          resolve(data);
        });
    });
  },
  [GET_ARCHIVED_CATEGORIES](context, options = {}) {
    context.commit(ARCHIVED_CATEGORIES_FETCHING);
    context.commit(ARCHIVED_CATEGORIES_ERROR, null);
    context.commit(SET_ARCHIVED_CATEGORIES_COUNT, 0);
    let limit = [0, 10];
    if ("limit" in options) {
      limit = options.limit;
    }
    options.archived = 1;

    return new Promise((resolve) => {
      ApiService.post(
        `/categories/getAllCategories/${limit[0]}/${limit[1]}`,
        options
      )
        .then(({ data }) => {
          context.commit(SET_ARCHIVED_CATEGORIES, data.data.categories);
          context.commit(SET_ARCHIVED_CATEGORIES_COUNT, data.count);
          context.commit(ARCHIVED_CATEGORIES_FETCHED);
          resolve(data);
        })
        .catch(({ data }) => {
          context.commit(
            ARCHIVED_CATEGORIES_ERROR,
            `${data.message} on ${data.url}`
          );
          context.commit(ARCHIVED_CATEGORIES_FETCHED);
          resolve(data);
        });
    });
  },
  [GET_SUB_CATEGORIES](context, options) {
    context.commit(SUB_CATEGORIES_FETCHING);
    context.commit(SET_SUB_CATEGORIES, []);
    context.commit(SET_SUB_CATEGORIES_COUNT, 0);
    let { limit, categoryId } = options;

    if (limit === undefined) {
      limit = [0, 10];
    }
    if (options.fetchAll) {
      return new Promise((resolve) => {
        ApiService.post(
          `/subCategories/allSubCategories/${limit[0]}/${limit[1]}`
        ).then(({ data }) => {
          context.commit(SET_SUB_CATEGORIES, data.data.subCategories);
          context.commit(SET_SUB_CATEGORIES_COUNT, data.data.subCategoryCount);
          context.commit(SUB_CATEGORIES_FETCHED);
          resolve(data);
        });
      });
    }
    context.commit(SET_SUB_CATEGORIES_CATEGORY, categoryId);

    return new Promise((resolve) => {
      ApiService.post(
        `/subCategories/getAllSubCategories/${categoryId}/${limit[0]}/${limit[1]}`,
        options
      ).then(({ data }) => {
        context.commit(SET_SUB_CATEGORIES, data.data.subCategories);
        context.commit(SET_SUB_CATEGORIES_COUNT, data.data.subCategoryCount);
        context.commit(SUB_CATEGORIES_FETCHED);
        resolve(data);
      });
    });
  },
  [GET_SUB_PRODUCTS](context, options) {
    context.commit(SUB_PRODUCTS_FETCHING);

    return new Promise((resolve) => {
      ApiService.post("/products/getAllProductsAdmin/0/100/", options).then(
        ({ data }) => {
          context.commit(SET_SUB_PRODUCTS, data.data.products);
          context.commit(SET_SUB_PRODUCTS_COUNT, data.data.producsCount);
          context.commit(SUB_PRODUCTS_FETCHED);
          resolve(data);
        }
      );
    });
  },
};
// Mutations
const mutations = {
  [SET_ALL_CATEGORIES](state, cats) {
    state.allCategories = cats;
  },
  // Categories Mutations
  [SET_ALL_CATEGORIES_COUNT](state, cats) {
    state.allCategoriesCount = cats;
  },
  [CATEGORIES_FETCHING](state) {
    state.categoriesFetching = true;
  },
  [CATEGORIES_FETCHED](state) {
    state.categoriesFetching = false;
  },
  // Subcategory Mutations
  [SUB_CATEGORIES_FETCHING](state) {
    state.subCategoriesFetching = true;
  },
  [SUB_CATEGORIES_FETCHED](state) {
    state.subCategoriesFetching = false;
  },
  [SET_SUB_CATEGORIES](state, cats) {
    state.subCategories = cats;
  },
  [SET_SUB_CATEGORIES_CATEGORY](state, catId) {
    state.categorySubId = catId;
  },
  [SET_SUB_CATEGORIES_COUNT](state, cats) {
    state.subCategoriesCount = cats;
  },
  [RESET_SUB_CATEGORY](state) {
    state.subCategories = [];
    state.categorySubId = null;
  },
  [SET_SUB_PRODUCTS](state, products) {
    state.subProducts = products;
  },
  [SET_SUB_PRODUCTS_COUNT](state, count) {
    state.subProductsCount = count;
  },
  [SUB_PRODUCTS_FETCHING](state) {
    state.subProductsFetching = true;
  },
  [SUB_PRODUCTS_FETCHED](state) {
    state.subProductsFetching = false;
  },
  [SET_ARCHIVED_CATEGORIES](state, cats) {
    state.archivedCategories = cats;
  },
  // Categories Mutations
  [SET_ARCHIVED_CATEGORIES_COUNT](state, cats) {
    state.archivedCategoriesCount = cats;
  },
  [ARCHIVED_CATEGORIES_FETCHING](state) {
    state.archviedCategoriesFetching = true;
  },
  [ARCHIVED_CATEGORIES_FETCHED](state) {
    state.archviedCategoriesFetching = false;
  },
  [ACTIVE_CATEGORIES_ERROR](state, err) {
    state.activeCategoriesError = err;
  },
  [ARCHIVED_CATEGORIES_ERROR](state, err) {
    state.archivedCategoriesError = err;
  },
};
// Export Default
export default {
  state,
  actions,
  getters,
  mutations,
};
