import ApiService from "../api.service";

export const GET_ADMINS = "get_admins";

const SET_ADMINS = "set_admins";
const SET_FETCHING = "set_fetching";

const state = {
  admins: [],
  fetching: false,
};
const actions = {
  [GET_ADMINS]({ commit }) {
    commit(SET_FETCHING, true);
    return new Promise((resolve) => {
      console.log("here");
      ApiService.post("/user/adminList")
        .then(({ data: response }) => {
          commit(SET_FETCHING, false);
          if (response.success === 1) {
            const { admins } = response;
            commit(SET_ADMINS, admins);
          }
        })
        .catch((error) => {
          commit(SET_FETCHING, false);
          console.log("file: admins.module.js | line 17 | error", error);
          resolve(error);
        });
    });
  },
};

const mutations = {
  [SET_ADMINS](state, admins) {
    state.admins = admins;
  },
  [SET_FETCHING](state, fetching) {
    state.fetching = fetching;
  },
};
export default {
  state,
  actions,
  mutations,
};
