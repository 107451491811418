import ApiService from "@/core/services/api.service";

// Actions

export const GET_PAYMENTS = "payments_action_1";

export const FETCHING_PAYMENTS = "payments_mutation_1";
export const FETCHED_PAYMENTS = "payments_mutation_2";
export const SET_PAYMENTS = "payments_mutation_3";
export const SET_PAYMENTS_COUNT = "payments_mutation_4";
export const SET_PAYMENTS_ERROR = "payments_mutation_5";
export const SET_TOTAL_EARNINGS = "payments_mutation_6";

// Fuel Imbursement Setters
export const GET_OUTGOING_PAYMENTS_HISTORY = "get_outgoing_payments_history";

/* Vendor Payments paraphernelia */
export const GetVendorPayments = "getVendorPayments";
export const VendorPayments = "setVendorPayments";
export const VendorPaymentsStats = "setVendorPaymentsStats";
export const VendorPaymentsCount = "setVendorPaymentsCount";
export const VendorPaymentsError = "setVendorPaymentsError";
export const VendorPaymentsLoading = "SET_VENDOR_PAYMENTS_LOADING";

const state = {
  transactions: [],
  fetchingError: null,
  count: 0,
  fetching: false,
  totals: {
    riderEarnings: 0,
    adminEarnings: 0,
  },
  /* Vendor Payments paraphernelia */
  vendorPayments: [],
  vendorPaymentsStats: {
    pending: 0,
    requested: 0,
    verified: 0,
  },
  vendorPaymentsCount: 0,
  vendorPaymentsLoading: false,
  vendorPaymentsError: null,
};
const getters = {
  getAllTransactions(state) {
    return state.transactions;
  },
  getVendorPayments(state) {
    return state.vendorPayments;
  },
};
const actions = {
  [GET_PAYMENTS]({ commit }, payload = {}) {
    payload.offset = payload.offset || 0;
    payload.limit = payload.limit || 10;
    commit(FETCHING_PAYMENTS);
    commit(SET_PAYMENTS, []);
    commit(SET_PAYMENTS_COUNT, 0);
    commit(SET_TOTAL_EARNINGS, {
      riderEarnings: 0,
      adminEarnings: 0,
    });

    commit(SET_PAYMENTS_ERROR, null);
    const { dates } = payload;
    return new Promise((resolve) => {
      ApiService.post(`/earnings/${payload.offset}/${payload.limit}`, { dates })
        .then(({ data: transactionsData }) => {
          commit(FETCHED_PAYMENTS);
          commit(SET_PAYMENTS_COUNT, transactionsData.count);
          commit(SET_PAYMENTS, transactionsData.transactions);
          commit(SET_TOTAL_EARNINGS, transactionsData.totals);
          resolve(transactionsData);
        })
        .catch(({ data }) => {
          commit(FETCHED_PAYMENTS);
          commit(SET_PAYMENTS_ERROR, `${data.message} on ${data.url}`);
          resolve(data);
        });
      resolve();
    });
  },
  // Get a list of vendor payments
  [GetVendorPayments](context, { limit, sort = "date" }) {
    console.log("file: payments.module.js | line 86 | limit", limit);
    ApiService.setHeader("content-type", "application/JSON");
    context.commit(VendorPayments, []);
    context.commit(VendorPaymentsLoading, true);
    return new Promise((resolve) => {
      ApiService.post(
        `/order/getVendorPayments/${limit[0]}/${limit[1]}?sort=${sort}`
      )
        .then(({ data }) => {
          console.log(data);
          context.commit(VendorPayments, data.payments);
          context.commit(VendorPaymentsStats, data.total);
          context.commit(VendorPaymentsCount, data.count);
          context.commit(VendorPaymentsLoading, false);
          resolve(data);
        })
        .catch(({ data }) => {
          context.commit(VendorPaymentsError, `${data.message} on ${data.url}`);
          context.commit(VendorPaymentsLoading, false);
          resolve(data);
        });
    });
  },
};
const mutations = {
  [FETCHING_PAYMENTS](state) {
    state.fetching = true;
  },
  [FETCHED_PAYMENTS](state) {
    state.fetching = false;
  },
  [SET_PAYMENTS](state, transactions) {
    state.transactions = transactions;
  },
  [SET_PAYMENTS_COUNT](state, count) {
    state.count = count;
  },
  [SET_PAYMENTS_ERROR](state, error) {
    state.fetchingError = error;
  },
  [SET_TOTAL_EARNINGS](state, totals) {
    state.totals = totals;
  },

  [VendorPayments](state, payments) {
    state.vendorPayments = payments;
  },
  [VendorPaymentsStats](state, stats) {
    console.log("file: payments.module.js | line 131 | stats", stats);
    state.vendorPaymentsStats = stats;
  },
  [VendorPaymentsCount](state, count) {
    state.vendorPaymentsCount = count;
  },
  [VendorPaymentsLoading](state, val) {
    state.vendorPaymentsLoading = val;
  },

  [VendorPaymentsError](state, err) {
    state.vendorPaymentsError = err;
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
