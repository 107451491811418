import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import fire from "@/core/plugins/firestore";
// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";
// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";
export const SET_TODOS = "setTodos";
// state
const state = {
  errors: [],
  user: {},
  isAuthenticated: !!JwtService.getToken(),
  todos: [],
};
// getters
const getters = {
  allTodos(state) {
    return state.todos;
  },
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
};
// actions
const actions = {
  [LOGIN](context, credentials) {
    // ApiService.setHeader();
    const { email, password } = credentials;
    return new Promise((resolve) => {
      ApiService.post("/user/login", {
        userId: email,
        password,
        userType: "ADMIN",
      })
        .then((response) => {
          console.log("file: auth.module.js | line 46 | response", response);
          const { data } = response;
          if (data.success) {
            context.commit(SET_AUTH, data);
          } else {
            context.commit(SET_ERROR, Array(data.message));
          }
          resolve(data);
        })
        .catch(({ message }) => {
          console.log("Console Log: : error", message);
          context.commit(SET_ERROR, [message]);
        });
    });
  },
  [LOGOUT](context) {
    // ApiService.setHeader();
    ApiService.post("/user/logout2").then(() => {
      context.commit(PURGE_AUTH);
    });
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("/user/createAdmin", { user: credentials })
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [VERIFY_AUTH](context) {
    return new Promise((resolve) => {
      if (JwtService.getToken()) {
        ApiService.get("/user/verifyToken")
          .then(({ data }) => {
            context.commit(SET_AUTH, data);
            resolve(true);
          })
          .catch((response) => {
            context.commit(SET_ERROR, response.data.errors);
            context.commit(PURGE_AUTH);
            resolve(true);
          });
      } else {
        resolve(true);
        context.commit(PURGE_AUTH);
      }
    });
  },
  [UPDATE_USER](context, payload) {
    const { email, username, password, image, bio } = payload;
    const user = {
      email,
      username,
      bio,
      image,
    };
    if (password) {
      user.password = password;
    }
    return ApiService.put("user", user).then(({ data }) => {
      context.commit(SET_AUTH, data);
      return data;
    });
  },
};
// mutations
const mutations = {
  [SET_TODOS](state, todos) {
    state.todos = todos;
  },
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  async [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    JwtService.saveToken(user.token, user.user._id);
    ApiService.setHeader("Authorization", `Bearer ${user.token}`);
    await fire
      .database()
      .ref(`Users/${user.user._id}`)
      .once("value")
      .then((snapshot) => {
        if (!snapshot.exists()) {
          fire.database().ref(`Users/${user.user._id}`).set({
            id: user.user._id,
            imageURL: user.user.profileImage,
            search: user.user.name.toLowerCase(),
            username: user.user.name,
          });
        }
      });
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
    ApiService.setHeader("Authorization", null);
  },
};
export default {
  state,
  actions,
  getters,
  mutations,
};
