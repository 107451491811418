import ApiService from "../api.service";

export const ORDERS_LOG = "GET_ORDERS_LOG";

const SET_FETCHING = "fetching";
const SET_ORDERS_LOG = "SET_ORDERS_LOG";

const state = {
  ordersLog: [],
  fetching: false,
};

const actions = {
  [ORDERS_LOG]({ commit }) {
    commit(SET_FETCHING, true);
    return new Promise((resolve) => {
      ApiService.post("/order/getOrderLog")
        .then(({ data: response }) => {
          const { log } = response;
          commit(SET_ORDERS_LOG, log);
          commit(SET_FETCHING, false);
          resolve(log);
        })
        .catch((error) => {
          console.log(
            "file: ordersLog.module.js | line 20 | ApiService.get | error",
            error
          );
          commit(SET_FETCHING, false);
          resolve(error);
        });
    });
  },
};
const mutations = {
  [SET_FETCHING](state, val) {
    state.fetching = val;
  },
  [SET_ORDERS_LOG](state, log) {
    state.ordersLog = log;
  },
};

export default {
  state,
  mutations,
  actions,
};
