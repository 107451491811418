import ApiService from "@/core/services/api.service";

// Actions types
export const FETCH_CUSTOMERS = "fetch_customers";

// Mutation types
export const SET_CUSTOMERS = "set_customers_data";
export const SET_CUSTOMERS_COUNT = "set_customers_data_count";
export const FETCHING_CUSTOMERS = "fetching_customers";
export const FETCHED_CUSTOMERS = "fetched_customers";
export const CUSTOMERS_ERROR = "customers_error";
// State
const state = {
  customers: [],
  customersCount: 0,
  fetchingCustomers: true,
  fetchingError: null,
};

// Getters
const getters = {
  getCustomers: (state) => state.customers,
  getCustomersCount: (state) => state.customersCount,
};

// Actions
const actions = {
  [FETCH_CUSTOMERS](context, options = {}) {
    let { limit } = options;
    if (!limit) {
      limit = [0, 10];
    }
    context.commit(FETCHING_CUSTOMERS);
    context.commit(SET_CUSTOMERS_COUNT, 0);
    context.commit(CUSTOMERS_ERROR, null);
    return new Promise((resolve) => {
      ApiService.post(
        `/customer/getAllCustomers/${limit[0]}/${limit[1]}`,
        options
      )
        .then(({ data }) => {
          const res = data.data;

          context.commit(SET_CUSTOMERS, res.customers);
          context.commit(SET_CUSTOMERS_COUNT, res.count);
          context.commit(FETCHED_CUSTOMERS);
          resolve(data);
        })
        .catch(({ data }) => {
          context.commit(CUSTOMERS_ERROR, `${data.message} on ${data.url}`);
          context.commit(FETCHED_CUSTOMERS);
          resolve(data);
        });
    });
  },
};

// Mutations
const mutations = {
  [SET_CUSTOMERS](state, customers) {
    state.customers = customers;
  },
  [SET_CUSTOMERS_COUNT](state, customers) {
    state.customersCount = customers;
  },
  [FETCHING_CUSTOMERS](state) {
    state.fetchingCustomers = true;
  },
  [FETCHED_CUSTOMERS](state) {
    state.fetchingCustomers = false;
  },
  [CUSTOMERS_ERROR](state, err) {
    state.fetchingError = err;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
