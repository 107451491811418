// STATE
const state = {
  isRegion: process.env.VUE_APP_REGION,
  region: process.env.VUE_APP_REGION,
  isStage: process.env.VUE_APP_STAGE,
};
// GETTERS
const getters = {
  region: (state) => state.region,
};
// ACTIONS
const actions = {};
// MUTATION

const mutations = {};
export default {
  state,
  getters,
  actions,
  mutations,
};
