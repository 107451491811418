import ApiService from "../api.service";
// Action Types
export const FETCH_ACTIVE_ORDERS = "fetch_active_orders";
export const FETCH_GRAPH_DATA = "fetch_graph_data";
const TOGGLE = "set_fetching";
const TOGGLE_GRAPH = "set_graph_fetching";
const SET_ORDERS = "set_orders";
const SET_GRAPH_DATA = "set_graph_data";
// State
const state = {
  count: 0,
  fetching: false,
  fetchingGraph: false,
  orders: [],
  graphOrders: null,
  graphDeliveries: null,
  graphUsers: null,
  graphCustomers: null,
};
const getters = {};
const actions = {
  [FETCH_ACTIVE_ORDERS]({ commit }) {
    commit(TOGGLE, true);
    return new Promise((resolve) => {
      commit(SET_ORDERS, { list: [], count: 0 });
      ApiService.post("/order/dashboardActiveorders/0/10")
        .then(({ data: response }) => {
          commit(SET_ORDERS, { list: response.orders, count: response.count });
          commit(TOGGLE, false);
          resolve(response);
        })
        .catch((error) => {
          console.error(
            "file: activeOrders.module.js | line 26 | error",
            error
          );
          commit(TOGGLE, false);
        });
    });
  },
  [FETCH_GRAPH_DATA]({ commit }) {
    return new Promise((resolve) => {
      commit(TOGGLE_GRAPH, true);
      commit(SET_GRAPH_DATA, { orders: null, deliveries: null });
      ApiService.get("/graphData")
        .then(({ data: response }) => {
          console.log(
            "file: activeOrders.module.js | line 39 | .then | response",
            response
          );
          commit(SET_GRAPH_DATA, response);
          commit(TOGGLE_GRAPH, false);
          resolve(true);
        })
        .catch((error) => {
          console.error(
            "file: activeOrders.module.js | line 40 | error",
            error
          );
          commit(TOGGLE_GRAPH, false);
          resolve(null);
        });
    });
  },
};

const mutations = {
  [TOGGLE](state, val) {
    state.fetching = val;
  },
  [SET_ORDERS](state, { list = [], count = 0 }) {
    state.count = count;
    state.orders = list;
  },
  [SET_GRAPH_DATA](state, data) {
    state.graphOrders = data.orders;
    state.graphDeliveries = data.deliveries;
    state.graphCustomers = data.customers;
    state.graphUsers = data.users;
  },
  [TOGGLE_GRAPH](state, val) {
    state.fetchingGraph = val;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
