// ACTION TYPES
export const PRODUCE_APP_ERROR = "api_action_1";
// MUTATION TYPES
export const SET_APP_ERROR = "api_mutation_1";
export const RESET_APP_ERROR = "api_mutation_2";

// STATE
const state = {
  appError: null,
};
// GETTERS
const getters = {
  getAppError(state) {
    return state.appError;
  },
};
// ACTIONS
const actions = {
  [PRODUCE_APP_ERROR]({ commit }, err) {
    commit(RESET_APP_ERROR);
    return new Promise((resolve) => {
      commit(SET_APP_ERROR, err);
      setTimeout(() => {
        commit(RESET_APP_ERROR);
      }, 3000);
      resolve(err);
    });
  },
};
// MUTATION

const mutations = {
  [SET_APP_ERROR](state, err) {
    state.appError = err;
  },
  [RESET_APP_ERROR](state) {
    state.appError = null;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
