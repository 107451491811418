import ApiService from "@/core/services/api.service";

// import OrdersFilteration from "@/assets/js/filter/filter.js";
// Action Types
export const GET_ALL_ACTIVE_ORDERS = "get_all_active_orders";
export const GET_ORDER_HISTORY = "get_order_history";
// Mutation types
/* active orders */
export const SET_ALL_ACTIVE_ORDERS = "set_all_active_orders";
export const SET_ALL_ACTIVE_ORDERS_COUNT = "set_all_active_orders_count";
export const SET_ACTIVE_ORDERS_ERROR = "set_active_orders_error";
/* history */
export const SET_ORDER_HISTORY = "set_order_history";
export const SET_ORDER_HISTORY_COUNT = "set_order_history_count";
export const SET_ORDER_HISTORY_ERROR = "set_order_history_error";
/* loader */
export const SET_ORDERS_LOADING = "set_orders_loading";
export const SET_ORDERS_LOADED = "set_orders_loaded";

export const SET_ORDERS_HISTORY_LOADING = "set_orders_history_loading";
export const SET_ORDERS_HISTORY_LOADED = "set_orders_history_loaded";

// State
const state = {
  activeOrders: [],
  historyOrders: [],
  activeOrdersCount: 0,
  activeOrdersError: null,
  historyOrdersCount: 0,
  ordersLoading: false,
  historyOrdersLoading: false,
  historyOrdersError: null,
};
// Getters
const getters = {
  allOrders(state) {
    return state.activeOrders;
  },
  allOrdersCount(state) {
    return state.activeOrdersCount;
  },
  allHistoryOrders(state) {
    return state.historyOrders;
  },
  allHistoryOrdersCount(state) {
    return state.historyOrdersCount;
  },
  getOrdersLoading(state) {
    return state.ordersLoading;
  },
  getOrdersHistoryLoading(state) {
    return state.historyOrdersLoading;
  },
};
// Actions
const actions = {
  [GET_ALL_ACTIVE_ORDERS](context, limit = [0, 10]) {
    ApiService.setHeader("content-type", "application/JSON");
    context.commit(SET_ACTIVE_ORDERS_ERROR, null);
    context.commit(SET_ORDERS_LOADING);

    return new Promise((resolve) => {
      ApiService.post(`/order/getAllOrdersAdmin/${limit[0]}/${limit[1]}`)
        .then(({ data }) => {
          console.log(data);
          console.log(3);
          context.commit(SET_ALL_ACTIVE_ORDERS, data.data.orders);
          context.commit(SET_ALL_ACTIVE_ORDERS_COUNT, data.data.count);
          context.commit(SET_ORDERS_LOADED);
          resolve(data);
        })
        .catch(({ data }) => {
          context.commit(
            SET_ACTIVE_ORDERS_ERROR,
            `${data.message} on ${data.url}`
          );
          context.commit(SET_ORDERS_LOADED);
          resolve(data);
        });
    });
  },
  [GET_ORDER_HISTORY](context, limit = [0, 10]) {
    ApiService.setHeader("content-type", "application/JSON");
    context.commit(SET_ORDER_HISTORY_ERROR, null);
    context.commit(SET_ORDERS_HISTORY_LOADING);

    return new Promise((resolve) => {
      ApiService.post(`/order/getDeliveredOrders/${limit[0]}/${limit[1]}`)
        .then(({ data }) => {
          context.commit(SET_ORDER_HISTORY, data.data.orders);
          context.commit(SET_ORDER_HISTORY_COUNT, data.data.count);
          // OrdersFilteration.init(data.data.orders)
          context.commit(SET_ORDERS_HISTORY_LOADED);
          resolve(data);
        })
        .catch(({ data }) => {
          context.commit(
            SET_ORDER_HISTORY_ERROR,
            `${data.message} on ${data.url}`
          );
          context.commit(SET_ORDERS_HISTORY_LOADED);
          resolve(data);
        });
    });
  },
};
// Mutations
const mutations = {
  [SET_ALL_ACTIVE_ORDERS](state, orders) {
    state.activeOrders = [...orders];
  },
  [SET_ALL_ACTIVE_ORDERS_COUNT](state, orders) {
    state.activeOrdersCount = orders;
  },
  [SET_ORDER_HISTORY](state, orders) {
    state.historyOrders = [...orders];
  },
  [SET_ORDER_HISTORY_COUNT](state, orders) {
    state.historyOrdersCount = orders;
  },
  [SET_ORDERS_LOADING](state) {
    state.ordersLoading = true;
  },
  [SET_ORDERS_LOADED](state) {
    state.ordersLoading = false;
  },
  [SET_ORDERS_HISTORY_LOADING](state) {
    state.historyOrdersLoading = true;
  },
  [SET_ORDERS_HISTORY_LOADED](state) {
    state.historyOrdersLoading = false;
  },
  [SET_ACTIVE_ORDERS_ERROR](state, err) {
    state.activeOrdersError = err;
  },
  [SET_ORDER_HISTORY_ERROR](state, err) {
    state.historyOrdersError = err;
  },
};
// Export Default
export default {
  state,
  actions,
  getters,
  mutations,
};
