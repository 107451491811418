import ApiService from "@/core/services/api.service";
// Action types
export const GET_ACTIVE_PROMOCODES = "promocode_action_1";
export const GET_ARCHIVED_PROMOCODES = "promocode_action_2";
// Mutation types
export const SET_ACTIVE_PROMOCODES = "promocode_mutation_1";
export const SET_ARCHIVED_PROMOCODES = "promocode_mutation_2";
// State
const state = {
  fetchingActive: true,
  fetchingArchived: true,
  active: [],
  activeCount: 0,
  archived: [],
  archivedCount: 0,
};
// Getters
const getters = {
  getAllActive(state) {
    return state.active;
  },
  getAllArchived(state) {
    return state.archived;
  },
};
// Actions
const actions = {
  [GET_ACTIVE_PROMOCODES]({ commit }, payload = {}) {
    const { limit = 10, offset = 0 } = payload;
    commit(SET_ACTIVE_PROMOCODES, { total: 0, promos: [], fetching: true });
    return new Promise((resolve) => {
      ApiService.get(`/promoCode/get?offset=${offset}&limit=${limit}`)
        .then(({ data: response }) => {
          commit(SET_ACTIVE_PROMOCODES, {
            total: response.count,
            promos: response.promos,
            fetching: false,
          });
        })
        .catch(({ data }) => {
          console.log("data", data);
          commit(SET_ACTIVE_PROMOCODES, {
            total: 0,
            promos: [],
            fetching: false,
          });
        });
      resolve();
    });
  },
  [GET_ARCHIVED_PROMOCODES]({ commit }, payload = {}) {
    const { limit = 10, offset = 0 } = payload;
    commit(SET_ARCHIVED_PROMOCODES, { total: 0, promos: [], fetching: true });
    return new Promise((resolve) => {
      ApiService.get(`/promoCode/get/archived?offset=${offset}&limit=${limit}`)
        .then(({ data: response }) => {
          commit(SET_ARCHIVED_PROMOCODES, {
            total: response.count,
            promos: response.promos,
            fetching: false,
          });
        })
        .catch(({ data }) => {
          console.log("data", data);
          commit(SET_ARCHIVED_PROMOCODES, {
            total: 0,
            promos: [],
            fetching: false,
          });
        });
      resolve();
    });
  },
};
// Mutators
const mutations = {
  [SET_ACTIVE_PROMOCODES](state, { promos, total, fetching }) {
    state.activeCount = total;
    state.active = promos;
    state.fetchingActive = fetching;
  },
  [SET_ARCHIVED_PROMOCODES](state, { promos, total, fetching }) {
    state.archivedCount = total;
    state.archived = promos;
    state.fetchingArchived = fetching;
  },
};

export default {
  state,
  actions,
  getters,

  mutations,
};
